<template>
	<tr>
		<td>
			{{ category.content }}
		</td>
		<td>
			<div class="d-flex flex-row">
				<v-chip class="mgn_chip mx-1" v-for="server in category.servers" :key="server.name">
					{{ `${server.name}` }}
				</v-chip>
			</div>
		</td>
		<td>
			<div class="d-flex flex-row">
				<v-chip class="mgn_chip mx-1" v-for="server_reward_cat in category.server_reward_categories" :key="server_reward_cat">
					{{ server_reward_cat }}
				</v-chip>
			</div>
		</td>
		<td>
			<v-dialog v-model="dialogDeleteCategory" max-width="450px">
				<template v-slot:activator="{ on, attrs }">
					<v-btn
						:disabled="category.isRetained"
						v-bind="attrs" 
						v-on="on" 
						icon 
						color="error"
					>
						<v-icon>mdi-delete</v-icon>
					</v-btn>
				</template>

				<v-card>
					<v-card-title>
						<v-icon class="mr-2">mdi-alert</v-icon>
						{{ category.content }}
					</v-card-title>

					<v-card-text>
						Are you sure you want to delete this category?
					</v-card-text>

					<v-card-actions>
						<v-spacer></v-spacer>
						<v-btn color="secondary" @click="dialogDeleteCategory = false">Cancel</v-btn>
						<v-btn color="error" :loading="loadingDelBtn" :disabled="loadingDelBtn" @click="DeleteCategory(category.id)">Delete</v-btn>
						<v-spacer></v-spacer>
					</v-card-actions>
				</v-card>
			</v-dialog>
		</td>
	</tr>
</template>

<script>
export default {
	props: ['category'],

	data() {
		return {
			dialogDeleteCategory: false,
			loadingDelBtn: false,
		}
	},

	methods: {
		DeleteCategory: async function(id) {
			try {
				this.loadingDelBtn = true;

				const request = await fetch(this.$store.state.base_url + '/categories/delete', {
					method: 'DELETE',
					body: JSON.stringify({
						category_id: id
					}),
					headers: {
						"Content-type" : "application/json; charset=UTF-8",
						"Authorization" : this.$cookies.get('guardian_key_session'),
					}
				});

				const res = await request.json();

				if (request.ok && request.status === 200) {	
					if (res.isCategoryDeleted) {
						this.$notify({
							group: 'main',
							type: 'success',
							title: this.$store.state.app_name,
							text: 'Category deleted successfully!'
						});

						this.$emit('CategoryDeleted');
					}
					else {
						this.$notify({
							group: 'main',
							type: 'error',
							title: this.$store.state.app_name,
							text: res.error
						});
					}
				}
				else {
					if (res.error) {
						if (this.$store.state.error_types.includes(res.error)) {
							this.sessionExpiredHandler(res);
						}
						else {
							throw res.error;
						}
					}
				}
			}
			catch (error) {
				this.$notify({
					group: 'main',
					type: 'error',
					title: this.$store.state.app_name,
					text: 'There was a network error. Please try again.'
				});
			}
			finally {
				this.loadingDelBtn = false;
			}
		}
	},
}
</script>