<template>
    <v-card>
		<v-form class="pa-2" @submit.prevent="SaveCategory">
			<v-card-title>
				<span class="text-h5">New Category</span>
			</v-card-title>

			<v-card-text>
				<v-container>
					<v-row>
						<v-col cols="12">
							<v-text-field 
								clearable
								v-model.trim="$v.title.$model"
								:error-messages="titleErrors"
								@input="$v.title.$touch()"
								@blur="$v.title.$touch()"
								label="Category Name*" 
								required
							></v-text-field>
						</v-col>

					</v-row>
				</v-container>
				<small>*indicates required field</small>
			</v-card-text>

			<v-card-actions>
				<v-spacer></v-spacer>
				<v-btn color="blue darken-1" text @click="$emit('CloseDialogNewCat')">Close</v-btn>
				<v-btn color="blue darken-1" text :loading="loadingBtn" :disabled="loadingBtn" type="submit">Save</v-btn>
			</v-card-actions>
		</v-form>
    </v-card>
</template>

<script>
import { required } from 'vuelidate/lib/validators';

export default {
	props: ['streamers'],

	data() {
		return {
			title: '',
			loadingBtn: false,
		}
	},

	validations() {
		return {
			title: {
				required
			},
		};
	},

	computed: {
		titleErrors() {
			const errors = [];
			if (!this.$v.title.$dirty) return errors;
			!this.$v.title.required && errors.push('Title is required.');
			return errors;
		},
	},

	methods: {
		AddSubCategory() {
			this.sub_categories.push({
				extra_field: false,
				name: ''
			});
		},

		async SaveCategory() {
			this.$v.$touch();

			if (!this.$v.$invalid) {
				this.loadingBtn = true;

				try {
					const request = await fetch(this.$store.state.base_url + '/categories/new', {
						method: 'POST',
						body: JSON.stringify({
							title: this.title,
						}),
						headers: {
							"Content-type" : "application/json; charset=UTF-8",
							"Authorization" : this.$cookies.get('guardian_key_session'),
						}
					});
					const res = await request.json();
					
					if (request.ok) {

						if (res.isCategoryAdded) {
							this.$v.title.$reset();
							this.title = '';

							this.$notify({
								group: 'main',
								type: 'success',
								title: this.$store.state.app_name,
								text: 'Category added successfully!'
							});

							this.$emit('NewCategoryAdded');
						}
						else if (res.error) {
							this.$notify({
								group: 'main',
								type: 'error',
								title: this.$store.state.app_name,
								text: res.error
							});
						}
					}
					else if (request.status === 401) {
						res.forEach((msg) => {
							this.$notify({
								group: 'main',
								type: 'error',
								title: this.$store.state.app_name,
								text: msg.error
							});
						});
					}
					else {
						if (res.error) {
							if (this.$store.state.error_types.includes(res.error)) {
								this.sessionExpiredHandler(res);
							}
							else {
								throw res.error;
							}
						}
					}
				}
				catch (error) {
					this.$notify({
						group: 'main',
						type: 'error',
						title: this.$store.state.app_name,
						text: error.error
					});
				}
				finally {
					this.loadingBtn = false;
				}
			}
		}
	},
}
</script>

<style>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}
</style>